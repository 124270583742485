// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-user-js": () => import("./../src/pages/create-user.js" /* webpackChunkName: "component---src-pages-create-user-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-js": () => import("./../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-ministry-js": () => import("./../src/pages/ministry.js" /* webpackChunkName: "component---src-pages-ministry-js" */),
  "component---src-pages-post-js": () => import("./../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../src/pages/privacy-policy-en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-public-ministry-js": () => import("./../src/pages/publicMinistry.js" /* webpackChunkName: "component---src-pages-public-ministry-js" */),
  "component---src-pages-pw-forget-js": () => import("./../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-report-js": () => import("./../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-signin-js": () => import("./../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-structure-js": () => import("./../src/pages/structure.js" /* webpackChunkName: "component---src-pages-structure-js" */),
  "component---src-pages-support-js": () => import("./../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonies-js": () => import("./../src/pages/testimonies.js" /* webpackChunkName: "component---src-pages-testimonies-js" */)
}

