module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Share Bibles","short_name":"Share Bibles","start_url":"/","background_color":"#1F79BC","theme_color":"#1F79BC","display":"standalone","icon":"src/assets/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6faf6e0f3869fa88a303d9195ba386b0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-174458390-1","head":true,"anonymize":true,"respectDNT":true},
    }]
